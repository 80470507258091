<template>
    <div>
        <Navbar page="Relatório Treinamento" link="/entregaveis" nameLink="Entregáveis" />

        <div class="mx-4 my-4 md:mx-8 md:my-8">
            <h1 class="text-3xl mb-5">Relatório Treinamento</h1>
            <a v-if="link" :href="link" target="_blank" class="text-base text-underline text-blue-500">Visualizar relatório: {{ link}}</a>
            <div class="grid grid-cols-12 gap-4">
                <div class="col-span-12 md:col-span-4">
                    <label for="tipo_relatorio" class="block text-sm font-medium"> 
                        Escolha o tipo do relatório
                    </label>
                    <select v-model="tipo_relatorio" for="tipo_relatorio" id="tipo_relatorio" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                        <option default value="Média geral em todos os treinamentos">Média geral em todos os treinamentos</option>
                        <option value="Média geral em determinado treinamento">Média geral em determinado treinamento</option>
                        <option value="Média de notas por setor em determinado treinamento">Média de notas por setor em determinado treinamento</option>
                        <option value="QuestoesVSErros">Questões com maior indice de erros</option>
                        <option value="Participacao">Relatório de participação</option>
                        <option value="ParticipacaoPessoa">Relatório de participação de uma pessoa</option>
                    </select>
                </div>
                <div v-if="tipo_relatorio === 'Média geral em determinado treinamento' || tipo_relatorio === 'QuestoesVSErros' || tipo_relatorio === 'Participacao'" class="col-span-12 md:col-span-4">
                    <label for="busca" class="block text-sm font-medium"> 
                        Treinamento
                    </label>
                    <select v-model="busca" for="busca" id="busca" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                        <option v-for="item in treinamentos" :key="item._id" :value="item._id">{{ item.nome }}</option>
                    </select>
                </div>
                <div v-if="tipo_relatorio === 'Média de notas por setor em determinado treinamento'" class="col-span-12 md:col-span-4">
                    <label for="busca" class="block text-sm font-medium"> 
                        Setor
                    </label>
                    <select v-model="busca" for="busca" id="busca" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                        <option v-for="item in setores" :key="item._id" :value="item._id">{{ item.nome }}</option>
                    </select>
                </div>
                <div v-if="tipo_relatorio === 'ParticipacaoPessoa'" class="col-span-12 md:col-span-4">
                    <label for="busca" class="block text-sm font-medium"> 
                        Pessoa
                    </label>
                    <select v-model="busca" for="busca" id="busca" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                        <option v-for="item in pessoas" :key="item._id" :value="item._id">{{ item.nome }}</option>
                    </select>
                </div>
                <div class="col-span-12 md:col-span-2" v-if="tipo_relatorio !== 'Participacao' && tipo_relatorio !== 'ParticipacaoPessoa'">
                    <label class="block text-sm font-medium"> 
                        Buscar
                    </label>
                    <button @click="start()" class="mt-2 bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-3xl text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">Buscar </button>
                </div>
                <div class="col-span-12 md:col-span-2" v-if="tipo_relatorio === 'Participacao'">
                    <label class="block text-sm font-medium"> 
                        Download
                    </label>

                    <a :href="`${this.url_api}/v1/avaliacoes/gerarRelatorioExcel?treinamento=${busca}&tipo_relatorio=${tipo_relatorio}&token=${this.$store.state.token}`" target="_blank" class="block mt-2 bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-3xl text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">Download </a>
                </div>
                <div class="col-span-12 md:col-span-2" v-if="tipo_relatorio === 'ParticipacaoPessoa'">
                    <label class="block text-sm font-medium"> 
                        Download
                    </label>

                    <a :href="`${this.url_api}/v1/avaliacoes/gerarRelatorioExcelPessoa?pessoa=${busca}&tipo_relatorio=${tipo_relatorio}&token=${this.$store.state.token}`" target="_blank" class="block mt-2 bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-3xl text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">Download </a>
                </div>
            </div>
            <div v-if="list && list.length" class="flex flex-col mt-4">
                <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-200">
                        <thead class="bg-gray-50">
                            <tr>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                    <svg @click="selecionarTodos" xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#000000" viewBox="0 0 256 256"><path d="M220,48V208a12,12,0,0,1-12,12H136a4,4,0,0,1,0-8h72a4,4,0,0,0,4-4V48a4,4,0,0,0-4-4H48a4,4,0,0,0-4,4v96a4,4,0,0,1-8,0V48A12,12,0,0,1,48,36H208A12,12,0,0,1,220,48ZM117.17,157.17,64,210.34,42.83,189.17a4,4,0,0,0-5.66,5.66l24,24a4,4,0,0,0,5.66,0l56-56a4,4,0,0,0-5.66-5.66Z"></path></svg>
                                 </th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                    Treinamento
                                </th>
                                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                    Nota média
                                </th>
                            </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                        <tr v-for="item in list" :key="item._id">
                            <td class="px-6 py-4 whitespace-nowrap">
                                <div class="flex items-center">
                                    <div class="text-sm text-gray-500">
                                    <input :checked="selecionados.map(el => el._id).indexOf(item._id) > -1" @change="(e) => selecionadosControl(item, e)" type="checkbox" class="rounded-sm" id="titularmenor">
                                    </div>
                                </div>
                            </td>
                            <td class="px-6 py-4 whitespace-nowrap">
                                <div class="flex items-center">
                                    <div class="text-sm text-gray-500">
                                        {{item.treinamento ? item.treinamento : ''}}
                                    </div>
                                </div>
                            </td>
                            <td class="px-6 py-4 whitespace-nowrap">
                                <div class="flex items-center">
                                    <div class="text-sm text-gray-500">
                                        {{ parseFloat((item.media || 0) * 100).toFixed(2) }} 
                                    </div>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                </div>
                </div>
                 <div v-if="selecionados && selecionados.length" class="grid grid-cols-12 gap-6 mt-4">
                    <div class="col-span-12 md:col-span-4">
                        <label for="titulo" class="block text-sm font-medium">
                            De um título para o relatório
                        </label>
                        <input v-model="titulo" type="text" name="titulo" id="titulo" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                        <button @click="gerarRelatorio()" class="text-white bg-yellow-400 hover:bg-yellow-500 rounded py-1 px-5 mt-5">
                        Gerar Relatório
                        </button>
                    </div>
                    <div class="col-span-12 md:col-span-6 bg-gray-100 py-4 px-4 rounded md:ml-6">
                    <label class="block text-sm font-medium">
                        Atividades:
                    </label>
                    <div class="my-2" v-for="(item, idx) in selecionados" :key="idx">
                        <span class="text-sm">{{ item.treinamento }}</span>
                    </div>
                    </div>
                </div>
            </div>
            <div v-if="questoes && questoes.length" class="flex flex-col mt-4">
                <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-200">
                        <thead class="bg-gray-50">
                            <tr>
                                <th scope="col" class="px-6 py-3 text-left text-sm font-medium text-gray-500 tracking-wider">
                                    <svg @click="selecionarTodos" xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#000000" viewBox="0 0 256 256"><path d="M220,48V208a12,12,0,0,1-12,12H136a4,4,0,0,1,0-8h72a4,4,0,0,0,4-4V48a4,4,0,0,0-4-4H48a4,4,0,0,0-4,4v96a4,4,0,0,1-8,0V48A12,12,0,0,1,48,36H208A12,12,0,0,1,220,48ZM117.17,157.17,64,210.34,42.83,189.17a4,4,0,0,0-5.66,5.66l24,24a4,4,0,0,0,5.66,0l56-56a4,4,0,0,0-5.66-5.66Z"></path></svg>
                                 </th>
                                <th scope="col" class="px-6 py-3 text-left text-sm font-medium text-gray-500 tracking-wider">
                                    Questão
                                </th>
                                <th scope="col" class="px-6 py-3 text-left text-sm font-medium text-gray-500 tracking-wider">
                                    Quantidade de pessoas que erraram
                                </th>
                            </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                        <tr v-for="item in questoes" :key="item._id">
                            <td class="px-6 py-4 whitespace-nowrap">
                                <div class="flex items-center">
                                    <div class="text-sm text-gray-500">
                                    <input :checked="selecionados.map(el => el._id).indexOf(item._id) > -1" @change="(e) => selecionadosControl(item, e)" type="checkbox" class="rounded-sm" id="titularmenor">
                                    </div>
                                </div>
                            </td>
                            <td class="px-6 py-4 ">
                                <div class="flex items-center">
                                    <div class="text-sm text-gray-500 w-160">
                                        {{item.perguntaDetalhes.pergunta}}
                                    </div>
                                </div>
                            </td>
                            <td class="px-6 py-4 ">
                                <div class="flex items-center">
                                    <div class="text-sm text-gray-500">
                                        {{ item.count }} 
                                    </div>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                </div>
                </div>
                 <div v-if="selecionados && selecionados.length" class="grid grid-cols-12 gap-6 mt-4">
                    <div class="col-span-12 md:col-span-4">
                        <label for="titulo" class="block text-sm font-medium">
                            De um título para o relatório
                        </label>
                        <input v-model="titulo" type="text" name="titulo" id="titulo" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                        <button @click="gerarRelatorio()" class="text-white bg-yellow-400 hover:bg-yellow-500 rounded py-1 px-5 mt-5">
                        Gerar Relatório
                        </button>
                    </div>
                    <div class="col-span-12 md:col-span-6 bg-gray-100 py-4 px-4 rounded md:ml-6">
                    <label class="block text-sm font-medium" v-if="list && list.length">
                        Atividades:
                    </label>
                    <label class="block text-sm font-medium" v-if="questoes && questoes.length">
                        Questões:
                    </label>

                    <div v-if="list && list.length" >
                        <div class="my-2" v-for="(item, idx) in selecionados" :key="idx" >
                            <span class="text-sm">{{ item.treinamento }}</span>
                        </div>
                    </div>
                    
                    <div v-if="questoes && questoes.length" >
                        <div class="my-2" v-for="(item, idx) in selecionados" :key="idx" >
                            <span class="text-sm">{{ item.perguntaDetalhes.pergunta }}</span>
                        </div>
                    </div>

                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            link: "",
            list: [],
            questoes: [],
            titulo: "",
            tipo_relatorio: "Média geral em todos os treinamentos",
            treinamentos: [],
            selecionados: [],
            setores: [],
            pessoas: [],
            busca: null
        }
    },
    methods: {
        async start() {
            this.list = [];
            this.questoes = [];

            if (this.tipo_relatorio === 'QuestoesVSErros') {
                const questoes = await this.$http.post(`/v1/avaliacoes/questoesVSErros`,{ tipo_relatorio: this.tipo_relatorio, treinamento: this.busca });
                this.questoes = questoes.data.data;
            } else {
                const listMediaSetor = await this.$http.post(`/v1/avaliacoes/getMedias`,{ tipo_relatorio: this.tipo_relatorio, busca: this.busca });
                this.list = listMediaSetor.data.data;
            }
        },
        async gerarRelatorio () {
            const req = await this.$http.post(`/v1/avaliacoes/gerarRelatorioEspecifico`, { titulo: this.titulo, tipo_relatorio: this.tipo_relatorio, busca: this.busca, data: this.selecionados });
            window.open(`${this.url_api}/upload?mimetype=${req.data.type}&filename=${req.data.filename}&folder=entregaveis`);
            this.link = `${this.url_api}/upload?mimetype=${req.data.type}&filename=${req.data.filename}&folder=entregaveis`;
        },
        async gerarRelatorioExcel () {
            //const req = await this.$http.post(`/v1/avaliacoes/gerarRelatorioExcel`, { tipo_relatorio: this.tipo_relatorio, treinamento: this.busca });
            //window.open(`${this.url_api}/upload?mimetype=${req.data.type}&filename=${req.data.filename}&folder=entregaveis`);
            //this.link = `${this.url_api}/upload?mimetype=${req.data.type}&filename=${req.data.filename}&folder=entregaveis`;
        },
        selecionarTodos () {

          for(let i = 0; i < this.list.length; i++){
            const item = this.list[i];
            if (this.selecionados.map(el => el._id).indexOf(item._id) > -1) {
              this.selecionados.splice(this.selecionados.map(el => el._id).indexOf(item._id), 1);
            } else {
              this.selecionados.push(item);
            }
          }

          for(let i = 0; i < this.questoes.length; i++){
            const item = this.questoes[i];
            if (this.selecionados.map(el => el._id).indexOf(item._id) > -1) {
              this.selecionados.splice(this.selecionados.map(el => el._id).indexOf(item._id), 1);
            } else {
              this.selecionados.push(item);
            }
          }

        },

        selecionadosControl(item, e) {
          if (this.selecionados.map(el => el._id).indexOf(item._id) > -1 && !e.target.checked) {
            this.selecionados.splice(this.selecionados.map(el => el._id).indexOf(item._id), 1);
          } else {
            this.selecionados.push(item);
          }
        },
    },
    async beforeMount() {
        const treinamentosReq = await this.$http.post(`/v1/treinamentos/list`, { all: true });
        this.treinamentos = treinamentosReq.data.data;

        const setoresReq = await this.$http.post(`/v1/setores/list`, { all: true });
        this.setores = setoresReq.data.data;

        const pessoasReq = await this.$http.post(`/v1/pessoas/list`, { all: true });
        this.pessoas = pessoasReq.data.data;
    }
}
</script>